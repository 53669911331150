.Sec6_main_div {
  width: 100%;
  height: 100vh;
  background-image: url("../../../../assets/Sec6.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  display: grid;
  place-items: center;
}
.Sec6_sub_div {
  width: 90%;
  height: 90%;

  display: grid;
  grid-template-columns: 45% 55%;
  place-items: center;
}
.text_of_sec6 {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 3.5rem;
}

.sec6_form_div {
  width: 100%;
  height: 100%;
  /* background-color: brown; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.from_main_div {
  width: 80%;
  height: 80%;
  background-color: rgb(255, 255, 255);
  border-top: 8px solid var(--button-bg-color);
  border-radius: 8px;
  box-shadow: #ffffffd1 0px 3px 8px;
}

.play_svg {
  position: relative;
  width: 70px !important;
  height: 70px;
  line-height: 62px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50% !important;
  animation: anim-two-btn 1.2s linear infinite;
  -webkit-transition: 0.5s linear;
}

@keyframes anim-two-btn {
  0% {
    box-shadow: 0 0 0 0 #fffffffc, 0 0 0 10px #ffffff69, 0 0 0 30px #ffffffb4;
  }
  100% {
    box-shadow: 0 0 0 10px #ffffff1a, 0 0 0 30px #ffffff1a, 0 0 0 50px #ffffff00;
  }
}

.contact_info_div_sec6 {
  padding-inline-start: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.contact_info_div_sec6 > h3 {
  font-size: 32px;
  color: var(--font-color);
  font-family: "Be Viettnam Pro SemiBold";
}

.contact_div_of_sec6 > h6 {
  font-size: 14x;
  color: var(--font-color);
  font-family: "Be Viettnam Pro SemiBold";
}
.contact_div_of_sec6 > h4 {
  font-size: 16px;
  color: var(--font-color);
  font-family: "Be Viettnam Pro SemiBold";
}
.contact_div_of_sec6 > a {
  font-size: 16px;
  color: var(--font-color);
  font-family: "Be Viettnam Pro SemiBold";
  text-decoration: none;
}
.from_main_div {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
.from_main_div > p {
  font-size: 16px;
  font-family: "Be Viettnam Pro Medium";
}
.from_main_div > h1 {
  font-size: 30px;
  font-family: "Be Viettnam Pro Bold";
  color: var(--seconsry_button-bg-color);
}

.input_all_div {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.fname_div {
  width: 100%;
  height: 50px;
  border: 1px solid #4a4a4a;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fname_div > input {
  width: 98%;
  height: 95%;
  border: none;
  outline: none;
}

.text_area_div {
  width: 100%;
  height: 130px;
  border: 1px solid #4a4a4a;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text_area_div > textarea {
  width: 98%;
  height: 98%;
  border: none;
  outline: none;
  resize: none;
}

@media screen and (max-width: 1024px) {
  .Sec6_main_div {
    width: 100%;
    height: 100%;
    background-image: url("../../../../assets/Sec6.png");

    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    display: grid;
    place-items: center;
  }
  .Sec6_sub_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 0rem;
  }
  .contact_info_div_sec6 {
    width: 100%;
    padding-inline-start: 0rem;
  }
  .contact_info_div_sec6 > h3 {
    font-size: 30px;
  }
  .from_main_div {
    width: 100%;
    height: 100%;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #07417bd1;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 767px) {
  .Sec6_main_div {
    width: 100%;
    height: 100%;
    background-image: url("../../../../assets/Sec6.png");

    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    display: grid;
    place-items: center;
  }
  .Sec6_sub_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 0rem;
  }
  .contact_info_div_sec6 {
    width: 100%;
    padding-inline-start: 0rem;
  }
  .contact_info_div_sec6 > h3 {
    font-size: 30px;
  }
  .from_main_div {
    width: 100%;
    height: 100%;
  }
}
