.Sec4_main_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 3rem 0rem;
}
.Sec4_sub_div {
  width: 90%;
  display: grid;
  place-items: center;
  grid-template-columns: 50% 50%;
  justify-content: center;
  gap: 1rem;
}
.sec4_main_img_div {
  width: 100%;
  height: 100%;
}
.sec4_images_div {
  width: 100%;
  height: 100%;
}
.sec4_images_div > img {
  width: 100%;
  aspect-ratio: 3/3;
  object-fit: contain;
}

.sec4_main_text_div {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}
.sec4_main_text_div > h1 {
  color: var(--seconsry_button-bg-color);
  font-size: 40px;
  font-family: "Be Viettnam Pro Bold";
}
.sec4_main_text_div > h5 {
  color: var(--seconsry_button-bg-color);
  font-size: 20px;
  font-family: "Be Viettnam Pro Bold";
}
.sec4_inner_text_div {
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}
.sec4_inner_text_div > span {
  font-size: 25px;
  color: var(--seconsry_button-bg-color);
}
.sec4_text_content_div > h3 {
  font-size: 22px;
  color: var(--seconsry_button-bg-color);
  font-family: "Be Viettnam Pro SemiBold";
}
.sec4_text_content_div > p {
  font-size: 15px;
  color: var(--secondry-font-color);
  font-family: "Be Viettnam Pro Regular";
}
@media screen and (max-width: 1040px) {
  .Sec4_sub_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
  }
  .sec4_main_text_div {
    width: 90%;
  }
  .sec4_inner_text_div {
    width: 100%;
  }
}
@media screen and (max-width: 850px) {
}
@media screen and (max-width: 768px) {
  .sec4_main_text_div > h1 {
    font-size: 30px;
  }
  .sec4_main_text_div > h5 {
    font-size: 17px;
  }
  .sec4_inner_text_div {
    flex-direction: column;
  }
  .sec4_inner_text_div > span {
    font-size: 30px;
  }
}
