.main_Service_sec1 {
  background: url("../../../assets/business.png");
  width: 100%;
  height: 400px;
  background-position: center center;
  background-size: cover;

  background-repeat: no-repeat;
  margin-top: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.main_Service_sec1 > h1 {
  font-family: "Be Viettnam Pro Bold";
  color: var(--font-color);
  font-size: 45px;
  border-bottom: none !important;
}

@media screen and (max-width: 1024px) {
  .main_Service_sec1 > h1 {
    font-family: "Be Viettnam Pro Bold";
    color: var(--font-color);
    font-size: 25px;
  }
}
@media screen and (max-width: 767px) {
  .main_Service_sec1 > h1 {
    width: 90%;
    font-family: "Be Viettnam Pro Bold";
    color: var(--font-color);
    font-size: 22px;
    text-align: center;
    padding-bottom: 10px;
  }
}
