.main_S_sec2_div {
  width: 100%;
  height: 100%;
  padding: 2rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub_main_Service_sec2 {
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
  /* background-color: aquamarine; */
}

.service_heading_sec2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}

.service_heading_sec2 > h1 {
  font-family: "Be Viettnam Pro Bold";
  color: var(--seconsry_button-bg-color);
  text-transform: capitalize;
  font-size: 30px;
  line-height: 42px !important;
}
.service_heading_text {
  font-family: "Be Viettnam Pro Bold";
  color: var(--seconsry_button-bg-color);
  text-transform: capitalize;
  font-size: 30px;
  line-height: 42px !important;
}

.service_heading_sec2 > p {
  color: var(--therd-font-color);
  font-family: "Be Viettnam Pro Medium";
  font-size: 14px;
}
/* ? card div start here */
.service_card_div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.srevices_card {
  width: 350px;
  height: 400px;
  background-color: var(--seconsry_button-bg-color);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.srevices_card {
  transition: opacity 0.6s ease;
}
.service_card_div:hover > :not(:hover) {
  opacity: 0.4;

  /* transform: translateY(10px); */
  /* transform: translate3d(10px); */
}
.services_images_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s_img_div {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}
.s_img_div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.services_text_div {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.services_text_div > h2 {
  color: var(--font-color);
  font-size: 23px;
  font-family: "Be Viettnam Pro SemiBold";
}

.services_text_div > p {
  color: var(--font-color);
  font-size: 14px;
  font-family: "Be Viettnam Pro Regular";
  text-align: center;
}
/* ? card div start here */

.service_card_div_of_mobile {
  display: none;
}
@media screen and (max-width: 1040px) {
  .service_card_div {
    grid-template-columns: repeat(2, 1fr);
  }
  .service_heading_sec2 > p {
    width: 90%;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .service_card_div {
    display: none;
  }
  .service_card_div_of_mobile {
    display: block;
    width: 100%;
  }
  .Servicepage_section2_main {
    width: 250px;
    height: 400px;
  }
  .main_section2_swiper {
    background-color: var(--seconsry_button-bg-color);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }
  .srevices_card {
    width: 90%;
    height: 100%;
  }
  .services_text_div > h2 {
    color: var(--font-color);
    font-size: 20px;
    font-family: "Be Viettnam Pro SemiBold";
  }
  .services_text_div > p {
    color: var(--font-color);
    font-size: 12px;
    font-family: "Be Viettnam Pro Regular";
    text-align: center;
  }
  .service_heading_sec2 > h1 {
    font-size: 25px;
    text-align: center;
  }
}

.main_service_list_div {
  width: 70%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  gap: 1rem;
}
.service_name_div {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border: 1px solid var(--button-bg-color);
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  color: var(--seconsry_button-bg-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.service_name_div:hover {
  transform: scale(1.1);
  background-color: var(--button-bg-color);
  color: white;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}
.service_name_div > p {
  font-size: 12px;
  font-family: "Be Viettnam Pro Medium";
  text-align: center;
  margin: 0;
}
.service_name_div > span {
  font-size: 18px;
  font-family: "Be Viettnam Pro Medium";
  text-align: center;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .main_service_list_div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
    gap: 1rem;
  }
}
