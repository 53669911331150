.Sec1_main_div {
  width: 100%;
  height: 100vh;
  background: url("../../../../assets/Bg1.png");
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: 101px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Sec1_sub_div {
  width: 80%;
  height: 90%;
  /* background-color: antiquewhite; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.heading_text_main {
  padding: 2rem;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.heading_text_main > h5 {
  text-align: center;
  font-family: "Be Viettnam Pro Regular";
  font-size: 16px;
  color: var(--font-color);
}
.heading_text_main > h1 {
  text-align: center;
  font-family: "Be Viettnam Pro Bold";
  font-size: 50px;
  color: var(--font-color);
}
.heading_text_main > h1 > span {
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: var(--font-color);
  -webkit-text-fill-color: transparent;
  font-family: "DM Sherif Regular";
}
.heading_text_main > p {
  text-align: center;
  font-family: "Be Viettnam Pro Regular";
  font-size: 18px;
  color: var(--font-color);
}
.button_of_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.btn1 {
  padding: 1rem 2rem;
  border-radius: 8px;
  outline: none;
  border: none;
  font-family: "Be Viettnam Pro Regular";
  background-color: var(--button-bg-color);
  color: var(--font-color);
  padding: 1rem 2rem;
  font-size: 15px;
}
.btn1:hover {
  background-color: var(--seconsry_button-bg-color);
  transition: all 1s ease-in-out;
  transform: scale(1.2);
}

.btn2 {
  padding: 1rem 2rem;
  border-radius: 8px;
  outline: none;
  /* border: 2px solid var(--button-bg-color); */
  border: none;
  font-family: "Be Viettnam Pro Regular";
  background-color: var(--seconsry_button-bg-color);
  color: var(--font-color);
  cursor: pointer;
  font-size: 15px;
}
.btn2:hover {
  background-color: var(--button-bg-color);
  transition: all 1s ease-in-out;
  transform: scale(1.2);
}
.Sec1_bottom_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_card_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}
.card_by_id {
  width: 150px;
  height: 150px;
  background-color: #8686867e;
  border-radius: 8px;
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
  display: grid;
  grid-template-rows: 50% 50%;
  place-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  cursor: pointer;
}
.icon {
  font-size: 35px;
  color: var(--font-color);
}
.card_by_id > h1 {
  text-align: center;
  font-family: "Be Viettnam Pro Regular";
  font-size: 18px;
  color: var(--font-color);
}
.card_by_id:hover {
  transition: all 1s ease-in-out;
  transform: scale(1.2);
  background-color: #02366a97;
  /* transform: translateY(-20px); */
}
.mobile_text {
  display: none;
}

@media screen and (max-width: 1300px) {
  .heading_text_main {
    width: 90%;
  }
}
@media screen and (max-width: 1024px) {
  .heading_text_main {
    width: 90%;
  }
}
@media screen and (max-width: 850px) {
  .heading_text_main {
    width: 100%;
  }
  .heading_text_main > h1 {
    font-size: 40px;
  }
  .heading_text_main > p {
    font-size: 15px;
  }
  .Sec1_bottom_div {
    align-items: center;
  }
}
@media screen and (max-width: 600px) {
  .Sec1_sub_div {
    width: 90%;
  }
  .web_text {
    display: none;
  }
  .mobile_text {
    display: block;
  }
  .Sec1_main_div {
    height: 100%;
    padding-bottom: 2rem;
  }
  .main_card_div {
    flex-wrap: wrap;
  }
  .heading_text_main > h1 {
    font-size: 30px;
  }
  .heading_text_main > h5 {
    font-size: 13px;
  }
  .heading_text_main > p {
    font-size: 11px;
  }
  .btn1,
  .btn2 {
    padding: 1rem;
    font-size: 10px;
  }
  .card_by_id {
    width: 120px;
    height: 120px;
    grid-template-rows: 50% 50%;
  }
  .card_by_id > h1 {
    font-size: 14px;
  }
}
