.main_ditails_of_blog {
  width: 100%;
  height: 100%;
  margin-top: 101px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub_main_div_of_blog {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  height: 90%;

  padding: 2rem 0rem;
}

.main_heding_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.backbtn_div {
  border: 1px solid var(--seconsry_button-bg-color);
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
}
.backbtn_div > span {
  font-size: 20px;
  color: var(--seconsry_button-bg-color);
}
.backbtn_div:hover {
  background-color: var(--seconsry_button-bg-color);
}
.backbtn_div:hover span {
  color: var(--font-color);
}
.main_heding_div > h1 {
  font-size: 30px;
  font-family: "Be Viettnam Pro Bold";
  color: var(--seconsry_button-bg-color);
}
.main_image_div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_box_blog {
  width: 50%;
  height: 50%;
  overflow: hidden !important;

  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 10px;
}

.image_box_blog > img {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
}

.main_text_div {
  width: 90%;
}

@media screen and (max-width: 767px) {
  .main_heding_div {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  .main_heding_div > h1 {
    font-size: 25px;
  }
  .backbtn_div {
    border: 1px solid var(--seconsry_button-bg-color);
    border-radius: 8px;
    padding: 5px 10px;
    cursor: pointer;
  }
  .image_box_blog {
    width: 80%;
    height: 80%;
    overflow: hidden !important;

    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 10px;
  }
}
