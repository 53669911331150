.main_service_sec_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 2rem 0rem;
}
.submain_service_sec2 {
  width: 90%;
  height: 90%;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  place-items: center;
  padding: 3rem 0rem;
}
.text_contentc_for_sec2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner_text_div_of_sec2 {
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.inner_text_div_of_sec2 > h2 {
  font-family: "Be Viettnam Pro SemiBold";
  font-size: 30px;
  color: var(--seconsry_button-bg-color);
}
.inner_text_div_of_sec2 > p {
  font-family: "Be Viettnam Pro Regular";
  font-size: 17px;
  color: var(--service-font-color);
}
.inner_text_div_of_sec2 > ul > li {
  font-family: "Be Viettnam Pro Regular";
  font-size: 14px;
  color: var(--service-font-color);
}
.inner_text_div_of_sec2 > ul {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.sub_bottom_text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.last_text {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  place-items: center;
}

.text_of_hedlines {
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.text_of_hedlines > h3 {
  font-family: "Be Viettnam Pro SemiBold";
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  .last_text {
    width: 100% !important;
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
