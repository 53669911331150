.Sec2_main_div {
  width: 100%;
  height: 100%;
  padding: 3rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Sec2_sub_div {
  width: 90%;
  height: 100%;
  display: grid;
  grid-template-columns: 40% 60%;
  place-items: center;
}
.imgage_sec2 {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.image_div {
  width: 90%;
  height: 90%;
}
.image_div > img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.sec2_content_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text_of_heading {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
}
.text_of_heading > h1 {
  font-family: "Be Viettnam Pro Bold";
  font-size: 40px;
  color: var(--secondry-font-color);
}
.text_of_heading > p {
  font-family: "Be Viettnam Pro Regular";
  font-size: 15px;
  color: var(--secondry-font-color);
}
.text_of_heading > p > span {
  font-family: "Be Viettnam Pro SemiBold";
  font-size: 16px;
  color: var(--secondry-font-color);
}
.second_text_of_sec2 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 2px;
}
.second_text_of_sec2 > p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 16px;
  color: var(--seconsry_button-bg-color);
  font-family: "Be Viettnam Pro SemiBold";
}
.second_text_of_sec2 > p > span {
  border: 1px solid var(--seconsry_button-bg-color);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 10px;
}

@media screen and (max-width: 1024px) {
  .Sec2_sub_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }
  .image_div > img {
    width: 50%;
    aspect-ratio: 3/4;
    object-fit: cover;
  }
  .image_div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 850px) {
  .Sec2_sub_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }
  .image_div > img {
    width: 50%;
    aspect-ratio: 3/4;
    object-fit: cover;
  }
  .image_div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .image_div > img {
    width: 90%;
    aspect-ratio: 3 / 4;
    object-fit: cover;
  }
  .text_of_heading {
    width: 90%;
  }
  .text_of_heading > h1 {
    font-size: 22px;
  }
  .text_of_heading > p {
    font-size: 12px;
  }
  .text_of_heading > p > span {
    font-size: 14px;
  }
  .second_text_of_sec2 > p {
    font-size: 12px;
  }
}
