.Sec3_main_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 3rem 0rem;
  background-color: var(--seconsry_button-bg-color);
}
.Sec3_sub_div {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.sec3_main_img_div {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.images_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.images_div > img {
  width: 90%;
  aspect-ratio: 3/3;
  object-fit: cover;
  border-radius: 12px;
}
.sec3_main_text_div {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}

.sec3_main_text_div > h1 {
  color: var(--font-color);
  font-size: 40px;
  font-family: "Be Viettnam Pro Bold";
}

.inner_text_div {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}
.inner_text_div > span {
  font-size: 40px;
  color: var(--font-color);
}
.text_content_div > h3 {
  font-size: 18px;
  color: var(--font-color);
}
.text_content_div > p {
  font-size: 15px;
  color: var(--font-color);
}
@media screen and (max-width: 850px) {
  .sec3_main_img_div {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .sec3_main_text_div {
    width: 95%;
  }
  .sec3_main_img_div {
    display: none;
  }
  .inner_text_div {
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid var(--button-bg-color);
    border-radius: 9px;
  }
}
