@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");
.main_sec2_of_AU {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 4rem 1rem;
}
.sub_main_Sec2_AU,
.sub_main_Sec2_AU_two {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  /* background-color: blue; */
  overflow: hidden;
}
.image_div_of_Sec2_AU {
  width: 100%;
  height: 100%;
  /* background-color: yellowgreen; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text_div_of_Sec2_AU {
  /* background-color: burlywood; */
  width: 95%;
  height: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}
.main_imagediv {
  width: 95%;
  height: 95%;
  border-radius: 10px;
  overflow: hidden;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
}
.div_of_icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.main_imagediv > img {
  width: 100%;
  height: 100%;

  object-position: left;
}
.main_imagediv2 {
  width: 95%;
  height: 95%;
  border-radius: 10px;
  overflow: hidden;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
}
.div_of_icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.main_imagediv2 > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.top_of_sec2_Au {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.first_text_of_sec2AU > h1 {
  font-family: "Be Viettnam Pro Bold";
  color: var(--seconsry_button-bg-color);
  text-transform: capitalize;
  font-size: 25px;
  line-height: 35px !important;
}
.first_text_of_sec2AU > p {
  color: var(--therd-font-color);
  font-family: "Be Viettnam Pro Medium";
  font-size: 14px;
}
.second_text_of_Sec2_au > h3 {
  font-family: "Be Viettnam Pro Bold";
  color: var(--seconsry_button-bg-color);
  text-transform: capitalize;
  font-size: 18px;
}
.second_text_of_Sec2_au > p {
  color: var(--therd-font-color);
  font-size: 14px;
  font-family: "Be Viettnam Pro Medium";
}
.name_and_sign_div_sec2_AU {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 19px;
  width: 100%;
  height: 130px;
}
.Name_of_funder_div {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100%;
  /* border: 1px solid #959595eb; */
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.Name_of_funder_div > h1 {
  font-size: 28px;
  color: var(--seconsry_button-bg-color);
  font-family: "Be Viettnam Pro Bold";
  margin-top: 10px;
}
.Name_of_funder_div > p {
  color: var(--therd-font-color);
  font-size: 16px;
  font-family: "Be Viettnam Pro Medium";
}
.Sign_of_funder_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #959595eb; */
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.Sign_of_funder_div > h1 {
  font-size: 35px;
  color: var(--seconsry_button-bg-color);
  font-family: "Dancing Script", cursive;
  font-weight: 700;
}

@media screen and (max-width: 1040px) {
  .main_sec2_of_AU {
    height: 100%;
  }
  .sub_main_Sec2_AU {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    /* background-color: blue; */
    overflow: hidden;
  }
  .first_text_of_sec2AU > h1 {
    font-size: 25px;
    line-height: 42px !important;
  }
  .name_and_sign_div_sec2_AU {
    height: 160px;
  }
}
@media screen and (max-width: 768px) {
  .name_and_sign_div_sec2_AU {
    flex-direction: column;

    height: auto;
  }
  .main_sec2_of_AU {
    padding: 2rem 1rem;
  }
  .Name_of_funder_div {
    width: 90%;
  }
  .sub_main_Sec2_AU_two {
    display: flex;
    flex-direction: column-reverse;
  }
  .text_div_of_Sec2_AU {
    /* background-color: burlywood; */
    width: 95%;
    height: 90%;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 10px;
    gap: 1rem;
  }
}
