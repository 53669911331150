.main_Au_div_sec5 {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0rem;
}
.sub_main_of_AU_sec5 {
  width: 95%;
  height: 95%;
  background-color: var(--main-background-color);
  padding: 3rem 0rem;
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 1rem;
  overflow: hidden;
  border-radius: 8px;
}
.img_div_Sec5_main {
  width: 100%;
  height: 100%;
  /* background-color: aqua; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.image_box_of_sec5_au {
  width: 65%;
  height: 80%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.image_box_of_sec5_au > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text_div_of_AU_sec5_main {
  width: 100%;
  height: 100%;
  /* background-color: #a3a3ff; */
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}
.text_div_of_AU_sec5_main > h1 {
  font-family: "Be Viettnam Pro Bold";
  color: var(--seconsry_button-bg-color);
  text-transform: capitalize;
  font-size: 30px;
  line-height: 30px !important;
}
.map_div_text_of_sec5_Au {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
}
.div_text_of_sec5_AU {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.div_text_of_sec5_AU > h4 {
  color: var(--therd-font-color);
  font-size: 20px;
  font-family: "Be Viettnam Pro Medium";
  line-height: 34px;
}

@media screen and (max-width: 1024px) {
  .main_Au_div_sec5 {
    height: 100%;
  }
  .sub_main_of_AU_sec5 {
    width: 95%;
    height: 95%;
    grid-template-columns: 1fr;
  }
  .image_box_of_sec5_au {
    width: 40%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}
@media screen and (max-width: 767px) {
  .image_box_of_sec5_au {
    width: 75%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .div_text_of_sec5_AU > h4 {
    color: var(--therd-font-color);
    font-size: 17px;
    font-family: "Be Viettnam Pro Medium";
    line-height: 34px;
  }
}
