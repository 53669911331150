.main_blog_sec2_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.sub_main_blog_sec2_div {
  width: 95%;
  height: 98%;
  /* background-color: yellow; */
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 1rem;
}
.blog_box_of_sec2 {
  width: 100%;
  height: 100%;
  /* background-color: tomato; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.blog_no1_box {
  width: 85%;
  height: 50%;
  background-color: var(--font-color);
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 15px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main_img_div_of_blogsec2 {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.image_box_of_blog_div_sec2 {
  width: 90%;
  height: 90%;

  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.image_box_of_blog_div_sec2 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog_btn {
  position: absolute;
  top: 9%;
  right: 8%;
  border: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 12px;
  font-family: "Be Viettnam Pro Regular";
  background-color: var(--seconsry_button-bg-color);
  color: var(--font-color);
  text-transform: capitalize;
}

.text_of_blog_no1 {
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.text_of_blog_no1 > h1 {
  font-size: 20px;
  font-family: "Be Viettnam Pro Bold";
  color: var(--seconsry_button-bg-color);
}
.text_of_blog_no1 > h3 {
  font-size: 16px;
  color: var(--button-bg-color);
  font-family: "Be Viettnam Pro Medium";
}
.text_of_blog_no1 > p {
  font-size: 14px;
  color: var(--button-bg-color);
  font-family: "Be Viettnam Pro Medium";
}

.blog_info_box {
  width: 100%;
  height: 100%;
  /* background-color: rgb(71, 227, 255); */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
}
.reent_blog_main_div {
  width: 90%;
  /* background-color: antiquewhite; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
.reent_blog_main_div > h3 {
  font-size: 20px;
  font-family: "Be Viettnam Pro Bold";
  color: var(--seconsry_button-bg-color);
  text-transform: capitalize;
}

.blog_text_div_of_sec2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  /* background-color: aquamarine; */
  padding: 10px;
  border-radius: 8px;
  border-bottom: 0.5px solid var(--main-background-color);
  cursor: pointer;
}
.recent_blog_sec2_img {
  width: 100px;
  height: 100px;
}
.recent_img_blog_box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
}
.recent_img_blog_box > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text_of_recent_blog_div {
  width: 70%;
}
.text_of_recent_blog_div > h4 {
  color: var(--seconsry_button-bg-color);
  font-size: 16px;
  font-family: "Be Viettnam Pro Bold";
}
.text_of_recent_blog_div > p {
  font-size: 14px;
  color: var(--button-bg-color);
  font-family: "Be Viettnam Pro Medium";
}
.tag_blog_main_div {
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.tag_blog_main_div > h2 {
  font-size: 20px;
  font-family: "Be Viettnam Pro Bold";
  color: var(--seconsry_button-bg-color);
  text-transform: capitalize;
}
.btn_tag {
  border: 1px solid var(--button-bg-color);
  background-color: var(--button-bg-color);
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 12px;
  text-transform: capitalize;
  color: var(--therd-font-color);
  font-family: "Be Viettnam Pro Bold";
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .sub_main_blog_sec2_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .blog_no1_box {
    width: 95%;
    height: max-content;
  }
  .blog_info_box {
    flex-direction: row;
  }
}
@media screen and (max-width: 768px) {
  .main_blog_sec2_div {
    height: 100%;
    padding: 2rem 1rem;
  }
  .blog_no1_box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
  }
  .blog_info_box {
    flex-direction: column;
  }
  .blog_btn {
    top: 4%;
    right: 7%;
  }
  .text_of_recent_blog_div > h4 {
    color: var(--seconsry_button-bg-color);
    font-size: 14px;
    font-family: "Be Viettnam Pro Bold";
  }
}
