.Sec5_main_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 3rem 0rem;
  background-color: var(--main-background-color);
}
.sec5_sub_div {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.sec5_sub_div > h1 {
  color: var(--seconsry_button-bg-color);
  font-family: "Be Viettnam Pro Bold";
  font-size: 40px;
}
.sec5_main_card_div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
.sec5_card {
  width: 280px;
  height: 280px;
  background-color: var(--car-bg-color);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
/* .sec5_card:hover {
  background-color: ;
} */
.sec5_card > span > svg > path {
  fill: var(--seconsry_button-bg-color) !important;
}
.sec5_card > span > svg > g > path {
  fill: var(--seconsry_button-bg-color) !important;
}
.sec5_card > span > svg {
  fill: var(--seconsry_button-bg-color) !important;
}
.sec5_card > span > svg {
  width: 50px;
  height: 50px;
}

.sec5_card_text > h3 {
  font-size: 20px;
  font-family: "Be Viettnam Pro SemiBold";
  color: var(--seconsry_button-bg-color);
}
.sec5_card_text > p {
  font-size: 14px;
  font-family: "Be Viettnam Pro Regular";
  color: var(--secondry-font-color);
}

.extra_card {
  width: 280px;
  height: 280px;
  background-image: url("../../../../assets/Sec5_card.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.card_sec5_inner_div {
  width: 100%;
  height: 100%;
  background-color: #0000008d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.card_sec5_inner_div > h3 {
  font-size: 20px;
  font-family: "Be Viettnam Pro SemiBold";
  color: var(--font-color);
  text-align: center;
}
.sec5_card {
  transition: opacity 0.6s ease;
}
.sec5_main_card_div:hover > :not(:hover) {
  opacity: 0.4;
  transform: translateY(10px);
}
.sec5_main_card_div_mobile {
  display: none;
}

@media screen and (max-width: 1040px) {
  .sec5_main_card_div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

@media screen and (max-width: 850px) {
  .sec5_main_card_div {
    display: none;
  }
  .sec5_main_card_div_mobile {
    display: block;
  }
  .swiper-3d .swiper-slide-shadow-left,
  .swiper-3d .swiper-slide-shadow-right {
    opacity: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  }
}
@media screen and (max-width: 768px) {
  .sec5_main_card_div {
    display: none;
  }
  .sec5_main_card_div_mobile {
    display: block;
  }
}
