.main_blog_sec1 {
  background: url("../../../assets/Contact_us_sec1.png");
  width: 100%;
  height: 400px;
  background-position: center center;
  background-size: 100% 100%;

  background-repeat: no-repeat;
  margin-top: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.main_blog_sec1 > h1 {
  /* font-family: "DM Sherif Regular"; */
  font-family: "Be Viettnam Pro Bold";
  color: var(--font-color);
  font-size: 45px;
  border-bottom: none !important;
  /* border-bottom: 10px double var(--font-color); */
}
.main_blog_sec1 > P {
  /* font-family: "DM Sherif Regular"; */
  font-family: "Be Viettnam Pro Bold";
  color: var(--font-color);
  font-size: 24px;

  /* border-bottom: 10px double var(--font-color); */
}

@media screen and (max-width: 1024px) {
  .main_blog_sec1 > h1 {
    font-family: "Be Viettnam Pro Bold";
    color: var(--font-color);
    font-size: 25px;
  }
  .main_blog_sec1 > P {
    font-family: "Be Viettnam Pro Bold";
    color: var(--font-color);
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .main_blog_sec1 > h1 {
    width: 90%;
    font-family: "Be Viettnam Pro Bold";
    color: var(--font-color);
    font-size: 22px;
    text-align: center;
    padding-bottom: 10px;
  }
  .main_blog_sec1 > P {
    font-family: "Be Viettnam Pro Bold";
    color: var(--font-color);
    font-size: 18px;
  }
}
