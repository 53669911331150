.main_Footer_div {
  width: 100%;
  height: 350px;
  background-image: url("../../../assets/Footer.png");
  background-size: 100% 100%;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Sub_main_footer {
  width: 95%;
  height: 95%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 1rem;
  place-items: center;
  padding: 1rem;
}
.logo_div {
  width: 100%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_logo_div {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
}
.main_logo_div > img {
  width: 80% !important;
  aspect-ratio: 3/1;
  object-fit: contain;
}
.main_logo_div > p {
  font-size: 16px;
  font-family: "Be Viettnam Pro Medium";
  color: var(--secondry-font-color);
}
.icon_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.icon_div > .icons {
  color: var(--seconsry_button-bg-color);
  font-size: 20px;
  aspect-ratio: 3/2;
  object-fit: cover;
  cursor: pointer;
}
.links {
  width: 30px;
  height: 30px;
  background-color: aliceblue;
  display: grid;
  place-items: center;
  color: var(--seconsry_button-bg-color);
  border-radius: 6px;
}
.links:hover {
  border-radius: 6px;
  background-color: var(--button-bg-color);
}
.main_service_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 15px;
  gap: 1rem;
}
.service_titel > h4 {
  color: var(--secondry-font-color);
  font-family: "Be Viettnam Pro Bold";
  font-size: 30px;
}
.Service_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}
.Service_name > h6,
.Service_name > a {
  color: var(--secondry-font-color);
  font-family: "Be Viettnam Pro Medium";
  font-size: 16px;
  cursor: pointer;
}
.Service_name > h6:hover {
  border-bottom: 1px solid #000000;
}
.Service_name > a:hover {
  color: var(--secondry-font-color);
  border-bottom: 1px solid #000000;
}

@media screen and (max-width: 1040px) {
  .main_Footer_div {
    width: 100%;
    height: 100%;
    padding: 1rem 0rem;
  }
  .Sub_main_footer {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  .main_logo_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .Service_name > h6,
  .Service_name > a {
    font-size: 14px;
  }
  .service_titel > h4 {
    font-size: 20px;
  }
  .main_service_div {
    width: 75%;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .Service_div {
    align-items: flex-start;
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .main_Footer_div {
    width: 100%;
    height: 100%;
    padding: 1rem 0rem;
  }
  .Sub_main_footer {
    grid-template-columns: 1fr;
  }
  .Service_name > h6,
  .Service_name > a {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  .service_titel > h4 {
    font-size: 20px;
  }
}
a {
  text-decoration: none !important;
}
