.AU_sec1_main_div {
  background: url("../../../assets/AU_heading_section.png");
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AU_sec1_main_div > h1 {
  /* font-family: "DM Sherif Regular"; */
  font-family: "Be Viettnam Pro Bold";
  color: var(--font-color);
  font-size: 55px;

  /* border-bottom: 10px double var(--font-color); */
}

@media screen and (max-width: 1024px) {
  .AU_sec1_main_div > h1 {
    /* font-family: "DM Sherif Regular"; */
    font-family: "Be Viettnam Pro Bold";
    color: var(--font-color);
    font-size: 35px;
    border-bottom: 8px dotted var(--font-color);
    /* border-bottom: 10px double var(--font-color); */
  }
}
