.main_Sec3_of_AU {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub_main_div_of_sec3_AU {
  width: 98%;
  height: 98%;
  background-color: var(--main-background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 1.4rem;
  gap: 2rem;
  border-radius: 4px;
}
.upper_heading_text {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.upper_heading_text > h1 {
  width: 70%;
  color: var(--seconsry_button-bg-color);
  font-size: 40px;
}
.content_div_sec3_AU {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-items: start;
  gap: 1.4rem;
}
.main_sec3_of_AU_card {
  width: 330px;
  height: 280px;
  background-color: var(--font-color);
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  border-radius: 8px;
  transition: scale 0.25s ease-in, opacity 0.25s ease-in, filter 0.25s ease-in;
  &:hover {
    scale: 1.1;
  }
  cursor: pointer;
}
.main_sec3_of_AU_card:has(.main_sec3_of_AU_card:hover)
  .main_sec3_of_AU_card:not(:hover) {
  scale: 0.8;
  opacity: 0.5;
  filter: blur(10);
}
/* .main_sec3_of_AU_card:hover {
  transform: translateY(-10px);
  background-color: rgba(255, 255, 255, 0.697);
  backdrop-filter: blur(5px);
} */
.main_sec3_of_AU_card > h3 {
  font-family: "Be Viettnam Pro Bold";
  color: var(--seconsry_button-bg-color);
  text-transform: capitalize;
  font-size: 18px;
}

.main_sec3_of_AU_card > p {
  color: var(--therd-font-color);
  font-size: 14px;
  font-family: "Be Viettnam Pro Medium";
}

@media screen and (max-width: 1040px) {
  .main_Sec3_of_AU {
    height: 100%;
    padding-bottom: 1rem;
  }
  .content_div_sec3_AU {
    grid-template-columns: 1fr 1fr;
  }
  .sub_main_div_of_sec3_AU {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
  .upper_heading_text {
    flex-direction: column;
  }
  .upper_heading_text > h1 {
    width: 90%;
    font-size: 29px;
  }
}
@media screen and (max-width: 767px) {
  .content_div_sec3_AU {
    grid-template-columns: 1fr;
  }
}
