.main_Service_sec3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  padding: 2rem 0rem;
}

.sub_main_sec3 {
  width: 98%;
  height: 98%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
._sec3_container1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 10px;
  gap: 1rem;
}

._sec3_container1 > h1 {
  font-family: "Be Viettnam Pro Bold";
  color: var(--seconsry_button-bg-color);
  text-transform: capitalize;
  font-size: 30px;
  line-height: 42px !important;
}

._sec3_container1 > p {
  color: var(--therd-font-color);
  font-family: "Be Viettnam Pro Medium";
  font-size: 14px;
}

._sec3_container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: auto;
}
.img_div_of_sec3 {
  width: 90%;
  height: 80%;
  object-fit: cover;
  overflow: hidden;
  -webkit-box-shadow: 20px 20px 0px -7px rgba(2, 54, 106);
  -moz-box-shadow: 20px 20px 0px -7px rgba(2, 54, 106);
  box-shadow: 20px 20px 0px -7px rgba(2, 54, 106);
}
.img_div_of_sec3 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
._sec3_container3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
.left_side_text_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}
.left_side_text_container > span {
  padding-top: 10px;
}

.text_box_sec3 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 0.8rem;
  width: 70%;
}
.text_box_sec3 > h2 {
  font-family: "Be Viettnam Pro Bold";
  color: var(--seconsry_button-bg-color);
  text-transform: capitalize;
  font-size: 25px;
  line-height: 42px !important;
}
.text_box_sec3 > p {
  color: var(--therd-font-color);
  font-family: "Be Viettnam Pro Medium";
  font-size: 14px;
}

@media screen and (max-width: 1030px) {
  .sub_main_sec3 {
    flex-direction: column;
  }
  ._sec3_container1 {
    width: 80%;
  }
  ._sec3_container3 {
    width: 80%;
    justify-content: flex-start;
  }
  .left_side_text_container {
    justify-content: flex-start;
  }
  .text_box_sec3 {
    width: 90%;
  }
}
@media screen and (max-width: 767px) {
  ._sec3_container1 > h1 {
    font-size: 20px;
    line-height: 35px !important;
  }
  .text_box_sec3 > h2 {
    font-size: 18px;
  }
  .left_side_text_container > span > svg {
    width: 18px;
    height: 18px;
  }
}
