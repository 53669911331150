.Main_AU_sec4_div {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub_main_div_of_sec4_Au {
  width: 95%;
  height: 95%;

  padding: 3rem 0rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.text_div_of_AU_sec4 {
  width: 100%;
  height: 100%;
  /* background-color: aquamarine; */
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.image_div_AU_sec4 {
  width: 100%;
  height: 100%;
  /* background-color: blue; */
  overflow: hidden;
  /* position: relative; */
}
/* for image css */
.img_div_of_sec4_AU {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_div_of_sec4_AU > img {
  width: 95%;
  aspect-ratio: 3/3;
  object-fit: contain;
}
/* for image css */
.text_div_of_AU_sec4 > h1 {
  width: 100%;
  color: var(--seconsry_button-bg-color);
  font-size: 30px;
  font-family: "Be Viettnam Pro Bold";
}
.div_text_content_in_sec4 {
  display: grid;
  grid-template-columns: 10% 90%;
  align-items: flex-start;
  justify-content: center;
}
.icon_div_of_sec4 {
  width: 100%;
  height: 100%;
  /* background-color: aliceblue; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.icon_div_of_sec4 > span {
  font-size: 30px;
  color: var(--seconsry_button-bg-color);
  line-height: 34px;
}
.div_text_of_sec4_AU {
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.div_text_of_sec4_AU > h3 {
  font-family: "Be Viettnam Pro Bold";
  color: var(--seconsry_button-bg-color);
  text-transform: capitalize;
  font-size: 18px;
}
.div_text_of_sec4_AU > p {
  color: var(--therd-font-color);
  font-size: 14px;
  font-family: "Be Viettnam Pro Medium";
}

@media screen and (max-width: 1024px) {
  .Main_AU_sec4_div {
    width: 100%;
    height: 100%;
  }
  .sub_main_div_of_sec4_Au {
    width: 95%;
    height: 95%;
    padding: 3rem 0rem;
    display: grid;
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 767px) {
  .div_text_content_in_sec4 {
    gap: 10px;
  }
  .div_text_of_sec4_AU {
    width: 95%;
  }
}
