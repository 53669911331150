.Sec2_main_div {
  width: 100vw;
  height: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Sec2_sub_main_div {
  width: 80%;
  height: 100%;
  padding: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}
.card_4_1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 290px;
}
.sec2_1_div {
  width: 290px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_of_sec2 {
  border: 1px solid #02366a;
  border-radius: 10px;
  background-color: white;
  padding: 15px 30px;
  color: #02366a;
  font-family: "Be Viettnam Pro Medium";
  cursor: pointer;
}
.sec2_2_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sec2_2_div > h2 {
  font-size: 22px;
  font-family: "Be Viettnam Pro Bold";
  text-align: center;
}
.sec2_3_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.card_sec4_main {
  width: 330px;
  height: 310px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  display: grid;
  grid-template-rows: 20% 30% 30%;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  gap: 1rem;
}
.card_sec4_main:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: scale(1.1);
}
.card_4_2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card_4_3 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.card_4_3 > h3 {
  font-family: "Be Viettnam Pro Bold";
  font-size: 18px;
  color: black;
  text-align: center;
}
.card_4_3 > p {
  font-family: "Be Viettnam Pro Regular";
  font-size: 14px;
  color: #4d4d4d;
  text-align: center;
}
@media screen and (max-width: 1100px) {
  .sec2_3_div {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 766px) {
  .sec2_3_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
  }
  .Sec2_sub_main_div {
    width: 100%;
    padding: 0;
  }
}
