@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
.Sec10_main_div {
  width: 100vw;
  height: 100%;
  padding: 2rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* background-color: #62b18d2f; */
}

.Sec10_sub_main_div {
  width: 85%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 2rem 0rem;
  border-radius: 8px;
}
.sub_inner_div_of_sec10 {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1rem;
  /* background-color: #e5e5e5; */
  width: 100%;
  height: 100%;
}
/* heding text and button */
.heding_div_of_section10 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
}
.heding_div_of_section10 > h1 {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 30px;
}

.heding_div_of_section10 > p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}
/* heding text and button */
/* image div start hare  */

.Right_side_main_div {
  width: 100%;
  height: 100%;
  /* background-color: rgb(237, 100, 100); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagediv_of_sec10 {
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imagediv_of_sec10 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* image div start hare  */
/* form div stat here */
.left_side_main_div {
  width: 100%;
  height: 100%;
  /* background-color: cornflowerblue; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.main_form_div {
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(246, 102, 102); */
}
form {
  width: 90%;
  height: 100%;
  /* background-color: cornflowerblue; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.5rem;
}
.contactus_input_div {
  width: 100%;
  height: 50px;
  border: 1px solid #00000012;
  border-radius: 8px;
  overflow: hidden;
}
.contactus_input_div > input {
  /* background-color: transparent; */
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  padding: 0px 10px;
}
::placeholder {
  color: #252525;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.contactus_textarea_div {
  width: 100%;
  height: 160px;
  border: 1px solid #00000012;
  border-radius: 8px;
  overflow: hidden;
}
.contactus_textarea_div > textarea {
  width: 100%;
  height: 100%;
  resize: none;
  outline: none;
  border: none;
  padding: 10px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}
.form_btn {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  background-color: #02366a;
  color: #ffffff;
  border-radius: 8px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}
.errors_msg_p {
  color: #ff0000;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  margin-bottom: 0;
}
.main_div_of_Contactus_input {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
}
.main_div_of_Contactus_text {
  width: 100%;
}

/* form div end here */
/* contact info div start here */
.bottomdiv_section10 {
  background-color: #d4d7d6;
  width: 100%;
  height: 100%;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub_main_div_of_bottom {
  width: 90%;
  height: 90%;
  /* background-color: #ffffff; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contact_div_of_webside {
  width: 80%;
  height: 90%;
  /* background-color: aqua; */
  padding: 1rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.conact_info_main {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
}
.contact_info_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}
.contact_info_text > p {
  color: #3d3d3d;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}
.contact_info_text > h5 {
  color: #000000;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}
.social_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #07417bd1;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 1040px) {
  .sub_main_div_of_bottom {
    width: 95%;
  }
  .main_form_div {
    height: 100%;
    align-items: flex-start;
  }
}
@media screen and (max-width: 840px) {
  .Sec10_sub_main_div {
    width: 95%;
  }
  .contact_div_of_webside {
    width: 100%;
  }
  .sub_main_div_of_bottom {
    flex-direction: column;
    gap: 1rem;
  }
  .imagediv_of_sec10 {
    width: 100%;
    height: 100%;
  }
  .imagediv_of_sec10 > img {
    object-fit: contain;
  }
  .main_form_div {
    height: 100%;
    align-items: flex-start;
  }
}
@media screen and (max-width: 769px) {
  .sub_inner_div_of_sec10 {
    grid-template-columns: 1fr;
  }
  .imagediv_of_sec10 {
    display: none;
  }
  .contact_div_of_webside {
    width: 85%;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    justify-content: center;
  }
  .main_form_div {
    height: 100%;
    align-items: flex-start;
  }
  form {
    height: 100%;
    gap: 0.5rem;
  }
}
