@font-face {
  font-family: "Be Viettnam Pro Black";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-Black.ttf");
}
@font-face {
  font-family: "Be Viettnam Pro BlackItalic";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-BlackItalic.ttf");
}
@font-face {
  font-family: "Be Viettnam Pro Bold";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-Bold.ttf");
}
@font-face {
  font-family: "Be Viettnam Pro ExtraBold";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-ExtraBold.ttf");
}
@font-face {
  font-family: "Be Viettnam Pro ExtraBoldItalic";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-ExtraBoldItalic.ttf");
}

@font-face {
  font-family: "Be Viettnam Pro ExtraLight";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-ExtraLight.ttf");
}

@font-face {
  font-family: "Be Viettnam Pro ExtraLightItalic";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-ExtraLightItalic.ttf");
}
@font-face {
  font-family: "Be Viettnam Pro Italic";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-Italic.ttf");
}
@font-face {
  font-family: "Be Viettnam Pro Light";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-Light.ttf");
}
@font-face {
  font-family: "Be Viettnam Pro LightItalic";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-LightItalic.ttf");
}
@font-face {
  font-family: "Be Viettnam Pro Medium";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-Medium.ttf");
}
@font-face {
  font-family: "Be Viettnam Pro MediumItalic";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-MediumItalic.ttf");
}
@font-face {
  font-family: "Be Viettnam Pro Regular";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-Regular.ttf");
}
@font-face {
  font-family: "Be Viettnam Pro SemiBold";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-SemiBold.ttf");
}
@font-face {
  font-family: "Be Viettnam Pro SemiBoldItalic";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-SemiBoldItalic.ttf");
}
@font-face {
  font-family: "Be Viettnam Pro Thin";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-Thin.ttf");
}
@font-face {
  font-family: "Be Viettnam Pro ThinItalic";
  src: url("./components/UI/Fonts/BeVietnamPro/BeVietnamPro-ThinItalic.ttf");
}
@font-face {
  font-family: "DM Sherif Regular";
  src: url("./components/UI/Fonts/DMSherif/DMSerifDisplay-Regular.ttf");
}
@font-face {
  font-family: "DM Sherif RegularItalic";
  src: url("./components/UI/Fonts/DMSherif/DMSerifDisplay-RegularItalic.ttf");
}

:root {
  --font-color: #ffffff;
  --car-bg-color: #eef2fa;
  --button-bg-color: #767d8a;
  --seconsry_button-bg-color: #07417bd1;
  --secondry-font-color: #000;
  --main-background-color: #b7b5b5;
  --therd-font-color: #252525;
  --service-font-color: #626262;
}
