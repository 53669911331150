.main_sec7_div {
  width: 100%;
  height: 200px;
  padding: 2rem 0rem;
}
.sub_main_sec7 {
  width: 100%;
  height: 100%;
  background-color: var(--main-background-color);
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;

  /* background: #9d0000; */

  /* Center slide text vertically */
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.main_img_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_img_div > img {
  width: 55%;
  aspect-ratio: 3/2;
  object-fit: contain;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgb(0, 0, 0));
}
